import { Title } from '@solidjs/meta';
import { Field, Label, Option, Select } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function SelectPage() {
	return (
		<>
			<Title>Select | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Select</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Select">
					<Field name="select">
						<Label>Select box</Label>
						<Select>
							<Option value="tacos">Tacos</Option>
							<Option value="burritos">Burritos</Option>
						</Select>
					</Field>
				</StyleCard>
			</div>
		</>
	);
}
